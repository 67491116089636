.mainContainer .headerContainer {
    display: flex;
    background-color: #e6e6e6;
    padding: 10px 0px;
}
.mainContainer .headerContainer .projectContainer {
    width: 20%;
    padding: 5px;
}
.mainContainer .headerContainer .projectActivityContainer {
    width: 20%;
    padding: 5px;
}
.mainContainer .headerContainer .dateContainer {
    width: 100%;
    padding: 5px;
}
.mainContainer .headerContainer .actionContainer {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.mainContainer .bodyContainer {
    display: flex;
    margin-top: 30px;
}
.mainContainer .bodyContainer .projectContainer {
    width: 20%;
    padding: 5px;
}
.mainContainer .bodyContainer .projectActivityContainer {
    width: 20%;
    padding: 5px;
}
.mainContainer .bodyContainer .dateContainer {
    width: 100%;
    padding: 5px;
}
.mainContainer .bodyContainer .actionContainer {
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.mainContainer .bodyContainer .actionContainer .add {
    background-color: #65a6d9;
    cursor: pointer;
    color: #fff;
    border-radius: 5px;
}
.mainContainer .bodyContainer .actionContainer .remove {
    background-color: red;
    cursor: pointer;
    margin-top: 5px;
    color: #fff;
    border-radius: 5px;
}
