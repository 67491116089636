.static-report-container {
  .static-report-btngroup {
    button {
      background: #006e7f;
      margin-left: 5px;
      position: absolute;
    }
  }
}

.static-report-wbp {
  width: 100%;
  background: #fff;
  padding: 10px;
}

.kgm-report-container {
  .highcharts-title,
  .highcharts-subtitle {
    visibility: hidden;
  }
}
.Report-MuiFormControl-root {
  padding-top: 0 !important;
  .MuiOutlinedInput-root {
    background-color: #fff;
    max-height: 100vh;
    padding-top: 0;
  }
  .MuiTextField-root {
    max-height: 100vh;
  }
}

.Report-Button-Style {
  margin: '2px';
}
