.event-radio {
    border: transparent;
    margin-top: 4px;
    background-color: rgb(30, 144, 255);
    display: block;
    width: 9px;
    height: 9px;
    margin-right: 12px;
    margin-left: 10px;
    margin-bottom: 2px;
    box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 40%);
    border-radius: 8px;
    float: left;
  }