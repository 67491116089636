@import '~ag-grid-community/styles/ag-grid.css';
::-webkit-scrollbar {
    width: 15px!important;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ccc; 
  }
