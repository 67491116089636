// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@300;400;500&display=swap');

@import 'scss/customAgGrid';
@import 'scss/staticReports';
@import 'scss/customCalendar';
@import 'scss/dashboard.scss';
@import 'scss/controls.scss';
@import 'scss/reports//customTiles.scss';

body.hierarchy {
  footer,
  .list_container {
    background-color: #f5f8fa;
  }
}
body.hierarchy {
  .list_container {
    overflow-x: auto;
  }
}
body.hierarchy {
  .list_container::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    box-shadow: inset 0 0 5px grey;
    border-radius: 4px;
  }
}
.MuiModal-root ~ .customPopover {
  z-index: 1500;
}

.customPopover {
  z-index: 100;
}

iframe#webpack-dev-server-client-overlay {
  display: none !important;
}

// .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle{
//     border :2px solid #006e7f !important;
// }
