.custom-tiles-container {
  width: 100%;

  .custom-tile-box {

    display: flex;
    flex-wrap: wrap;
    background: #fff;
    overflow: auto;
    margin-bottom: 10px;

    .custom-tile-row {
      flex: 1;

      &.divider {
        border-right: 1px solid #BCBCBC;
      }

      &.divider:nth-last-child(1) {
        border-right: none;
      }

      &.custom-tile-detail-card {
        flex: 0 0 50%;
      }

      .chart-area {
        .highcharts-credits {
          display: none;
        }

        .doughnut-container {

          .content {
            text-align: center;

            .title {
              color: #3B3B3B;
            }

            .sub-title {
              color: #5E5E5E;
              font-size: 12px;
            }
          }

        }

        .circle-container {

          // display: flex;
          .chart-box {
            width: 80%;
            display: inline-block;
            vertical-align: middle;
          }

          .icon-box {
            width: 20%;
            display: inline-block;
            vertical-align: middle;
          }

        }

        .card-widget-container {
          color: white;
          border-radius: 6px;
          padding: 15px;
          margin: 10px;
          background: rgb(119, 208, 154);

          .chart-box {
            display: flex;
            min-height: 80px;
            align-items: center;
          }

          .icon-box {
            background: rgba(0, 0, 0, 0.2);
            border-radius: 100%;

            .icon {
              height: 46px;
              width: 46px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;

              span {
                width: 80%;
                height: 80%;
              }
            }
          }

          .body {
            padding-left: 15px;
            font-size: 30px;

            .value {
              font-size: 30px;
            }

            .header {
              font-size: 15px;
            }
          }
        }

        // KEY VALUE WIDGET CONTAINER
        .keyvalue-widget-container {
          border-radius: 6px;
          padding: 15px;

          .keyvalue-box {
            display: flex;
            // min-height: 50px;
            align-items: center;
            justify-content: space-around;

            .header {
              font-size: 15px;
              width: 40%;
            }

            .value {
              font-size: 13px;
              background: #70B846;
              padding: 10px;
              min-width: 130px;
              color: #fff;
              text-align: center;
            }


          }

        }

        // MATRIX WIDGET 
        .matrix-widget-container {
          border-radius: 6px;
          padding: 0px 15px;

          display: flex;
          align-items: center;

          .key-header {
            font-size: 15px;
            width: 15%;
          }
          .key-body {
            .value-box {
              display: flex;

              .value-item {

                padding: 12px 14px;
                // min-width: 105px;
                flex-grow: 1;
                text-align: center;
                margin: 10px;
                background: #F3F3F3;
                border-radius: 5px;
                color: #0C0C0C;
                  .items {
                    .value {
                      font-size: 18px;
                    }
      
                    .header {
                      font-size: 9px;
                      margin-top: 5px;
                    }
                  }
              }
          }
          }
        }
      }
    }
  }
}