.custom-calendar {
  .year-calendar {
    .calendar-cell {
      height: 120px;
      border-right: 1px solid #e1e1e1;
      border-top: 1px solid #e1e1e1;
      padding-left: 15px;
      padding-right: 15px;
      cursor: pointer;

      .month-name {
        color: #3c3c3c;
        font-weight: 600;
        margin-top: 0;
        margin-left: 5px;
        float: left !important;
        opacity: 0.7;
        width: 100%;
        font-size: 0.9em;
      }

      .badge {
        display: inline-block;
        min-width: 10px;
        padding: 3px 7px;
        font-size: 12px;
        font-weight: normal;
        color: #ffffff;
        line-height: 1;
        vertical-align: middle;
        white-space: nowrap;
        padding: 4px 6px 4px;
      }

      .holidays-badge {
        background: #fff;
        border: 2px solid #8dc63f;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        color: #8dc63f;
        padding-top: 9px;
        font-weight: 600;
        margin-top: 6px;
        margin-left: 20px;
        text-align: center;
        font-size: 15px;
      }

      &:hover {
        background-color: #d9f6ff;
      }

      position: relative;

      .calendar-arrow-tick {
        position: absolute;
        border: 1px solid #e1e1e1;
        border-top: 0 solid;
        border-radius: 0 0 5px 5px;
        background-color: #ededed;
        bottom: -25px;
        padding: 0 2px;
        cursor: pointer;
        z-index: 5;
        width: 26px;
        margin-left: 38%;
      }

      .calendar-arrow-tick.hidden {
        display: none;
      }
    }

    /** SLIDE BOX **/
    .calendar-slide-box {
      &.hidden {
        display: none;
      }
      padding: 20px;
      color: #fff;
      background-color: #555;
      -webkit-box-shadow: inset 0 0 15px 0 rgb(0 0 0 / 50%);
      box-shadow: inset 0 0 15px 0 rgb(0 0 0 / 50%);
      float: left;
      width: 100%;
      .cal-event-list .event.pull-left {
        margin-top: 6px;
      }

      .cal-slide-content .list-unstyled {
        margin: 0;
        li {
          margin-bottom: 5px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      a {
        &.event-item {
          text-decoration: none;
          cursor: default;
        }

        &.event-item-action {
          margin-left: 0;
          padding-left: 0;
          outline: none;
          .eventsTrigger {
            text-transform: uppercase;
            display: inline-block;
            padding: 1px 3px;
            font-size: 10px;
            font-weight: 700;
            margin-left: 5px;
            background: #007ecc;
            border-color: #007ecc;
            color: #fff;
          }
        }
      }

      .cal-day-selected,
      .cal-day-hour-part-selected {
        background-color: #ededed;
        border: 1px solid #6ebe44;
      }

      .event-radio {
        width: 15px;
        height: 15px;
        border: transparent;
        margin-top: 4px;
        background-color: rgb(30, 144, 255);
        display: block;
        width: 15px;
        height: 15px;
        margin-right: 2px;
        margin-bottom: 2px;
        -webkit-box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 40%);
        box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 40%);
        border-radius: 8px;
        float: left;
      }

      .event-list {
        .event-item {
          color: white;
          margin: 0px 12px;
        }
      }

      .list-unstyled {
        list-style: none;
      }
    }
  }
}
