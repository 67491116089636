.dashboard-highpriority-widget {
  .kgm-accordian-header {
    background-color: #f12711;
  }

  .highpriority-background {
    background-color: #f1271112 !important;
  }

  .highpriority-widget-title {
    color: #ffffff !important;
    font-weight: bold;
    margin-left: 11px;
  }

  .highpriority-widget-text {
    color: #585858;
  }

  .highpriority-widget-timediff {
    color: #f24633;
  }

  .kgm-accordian-content {
    color: #585858;
  }

  .highpriority-badge-body {
    margin-left: 24px;
    height: 24px;
    width: 24px;
    border-radius: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f77070;
    .highpriority-badge-count {
      color: #ffffff;
    }
  }

  // when content division required then we will use this classes in HP widget
  // .highpriority-row-content {
  //   // justify-content: normal;
  //   .highpriority-row {
  //     // width: 33%;
  //   }
  // }
}
