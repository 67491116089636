@import '~ag-grid-community/styles/ag-grid.css';

/* GRID COMMON */
.mouseevents-none {
  cursor: not-allowed;
  pointer-events: none;
}

.ag-theme-alpine {
  font-family: ('Lexend deca', sans-serif) !important;
  --ag-input-border-color: #006e7f;
  /* width */
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  // /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  // /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 5px;
    &:hover {
      background: #cccccc;
      cursor: pointer;
    }
  }
  .ag-header-cell-text {
    color: #006e7f;
    font-size: 14px;
  }
  .ag-cell {
    line-height: 30px;
  }
  .ag-cell-wrapper {
    height: 100%;
  }
  .ag-row {
    font-size: 14px;
  }
  .ag-black {
    color: #516f90;
  }
  .ag-blue {
    color: #0066b3;
    cursor: pointer;
  }
  .actions_cell {
    width: fit-content !important;
  }
  .ag-row > .ag-cell:first-child {
    padding-left: 15px;
  }
  .ag-header-row > .ag-header-cell {
    padding-left: 5px;
    padding-right: 0;
    color: #bbb6b6;
  }
  // .ag-header , .ag-theme-alpine .ag-header-row > .ag-header-cell , .ag-header.ag-header-allow-overflow .ag-header-row{
  //   height:30px!important;
  //   max-height: 30px!important;
  //   min-height:30px!important;
  //  }
  .ag-header {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .ag-header-row > .ag-header-cell:first-child {
    padding-left: 16px;
    padding-right: 0;
  }
  .ag-cell {
    padding-left: 5px;
    padding-right: 0;
  }
  .ag-checkbox-input-wrapper.ag-checked::after {
    color: #006e7f;
  }
  .ag-ltr .ag-header-select-all {
    margin-right: 11px;
  }
  .ag-ltr .ag-selection-checkbox {
    margin-right: 5px;
  }
  .ag-ltr .ag-cell-range-single-cell {
    border-color: transparent;
  }
  .ag-body-horizontal-scroll,
  .ag-body-horizontal-scroll-viewport,
  .ag-body-horizontal-scroll-container {
    height: 7px !important;
    min-height: 16px !important;
    max-height: 20px !important;
    width: 102%;
    ::-webkit-scrollbar:horizontal {
      height: 16px;
    }
  }
  .ag-body-vertical-scroll,
  .ag-body-vertical-scroll-viewport,
  .ag-body-vertical-scroll-container {
    width: 7px !important;
    max-width: 12px !important;
    min-width: 15px !important;
    height: 100%;
    ::-webkit-scrollbar:vertical {
      width: 30px;
      // height: 101px;
    }
  }
  .ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper,
  .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container,
  .ag-body-viewport.ag-layout-print .ag-center-cols-clipper,
  .ag-body-viewport.ag-layout-print .ag-center-cols-container {
    min-height: 30px !important;
  }
  .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
    padding-top: 48px !important;
  }
  .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-viewport {
    min-height: 30px !important;
  }
  .ag-center-cols-container {
    min-height: 30px !important;
  }
  .ag-body-viewport {
    .ag-center-cols-viewport {
      min-height: 30px !important;
    }
  }
  .ag-root-wrapper.ag-layout-normal.ag-ltr {
    min-height: 100px;
  }
  .ag-react-container {
    width: 100%;
    text-align: left;
  }
  .ag-root-wrapper {
    border: none;
    .ag-root-wrapper-body {
      border-radius: 6px;
      border: 1px solid #babfc7;
    }
  }
  .ag-ltr .ag-row-group-leaf-indent {
    // margin-left: 2px;
    margin-left: 8px;
  }
  .ag-status-bar {
    .pagination-right-box {
      select {
        outline: none;
      }
      ul {
        margin-bottom: 5px;
        margin-right: 5px;
      }
      li {
        display: inline;
      }
    }
  }
  .ag-status-bar {
    border-top: none;
    height: 35px;
    padding-left: 12px;
    .floating-add-container {
      height: 35px;
      width: 100px;
      background: rgb(255, 255, 255);
      margin-top: -1px;
      border-top: none;
      border-right: 1px solid rgb(186, 191, 199);
      border-bottom: 1px solid rgb(186, 191, 199);
      border-left: 1px solid rgb(186, 191, 199);
      border-image: initial;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      position: absolute;
      -webkit-box-shadow: 0px 6px 8px -1px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 0px 6px 8px -1px rgba(0, 0, 0, 0.18);
      box-shadow: 0px 6px 8px -1px rgba(0, 0, 0, 0.18);

      .floatingAddButton {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:hover {
          color: #33475b;
        }
      }
    }
  }
  .ag-cell-inline-editing {
    padding-left: 0;
    height: 100%;
    border-color: #ccc !important;
    border-width: 1px !important;
    &:hover {
      border-color: #006e7f !important;
      border-width: 0.5px !important;
    }
    &.ag-cell-focus {
      border-color: #006e7f !important;
      border-width: 2px !important;
    }
    .ag-cell-wrapper > *:not(.ag-cell-value):not(.ag-group-value) {
      --ag-internal-calculated-line-height: 100%;
      height: 100%;
      --ag-font-family: 'Lexend deca', sans-serif;
    }
  }
  input {
    font-family: 'Lexend deca', sans-serif;
  }
  .MuiFormControl-root {
    .MuiOutlinedInput-root.Mui-focused fieldset {
      outline: none;
      box-shadow: 0 0 2px 0.1rem rgba(114, 166, 205, 0.4);
      border-color: var(--ag-input-border-color, var(--ag-border-color, #babfc7)) !important;
      border-width: 1px !important;
    }
  }
}
/** PIVOT LOGIC **/
.kgm-pivot-cell-select {
  background-color: rgb(142, 185, 121);
}
/** END PIVOT LOGIC **/
.kgm-plus-icon,
.kgm-minus-icon {
  display: block;
  height: 0.6em;
  width: 0.6em;
  font-size: 25px;
  text-align: center;
  line-height: 0.55em;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-weight: lighter;
  color: #ffffff;
  background-color: #006e7f;
  &.radius {
    border-radius: 25%;
  }
  &.radius-25 {
    border-radius: 25%;
  }
  &.no-border {
    color: black;
    background: none;
    font-weight: bold;
    font-size: 30px;
  }
}

.kgm-plus-icon::before {
  display: block;
  content: '+';
  font-size: 19px;
}
.kgm-minus-icon::before {
  display: block;
  content: '-';
}

/** reports grid css **/
.kgm-report-aggrid {
  .report-rtl-btngroup {
    button {
      background: #2d72b9;
      margin-left: 5px;
    }
    margin: 0 10px;
  }
  .ag-floating-bottom-container {
    .ag-row-pinned {
      border: 0;
      background-color: #fff;
      font-weight: bold;
    }
  }
  .hierarchy-nested-row {
    background: #FFEFD5;
  }
}

.kgm-report-hw {
  height: 70vh;
  width: 100%;
  // .ag-cell{
  //   text-align: left;
  // }
    .ag-cell {
      padding-left: 10px;
    }
    .ag-header-cell-label {
      padding-left: 10px;
    }
    .rtl-header {
      direction: rtl;
      text-align: right;
      padding-right: 15px !important;
    }
    .ag-cell {
      padding-left: 10px;
    }
    .ag-header-cell-label {
      padding-left: 10px;
    }
    .rtl-header {
      direction: rtl;
      text-align: right;
      padding-right: 15px !important;
    }
}
.ReportcellValue{
  text-align: left;
}

.kgm-report-hwm {
  height: 85vh;
  width: 100%;
  // margin: 10px;
}

/** reports grid css **/

.floatActionsBox {
  margin-left: 0;
  position: absolute;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 20px;
  line-height: 8px;
  border: 1px solid #ccc;
  z-index: 1;
  .no-action {
    display: inline;
  }
  .action-icon {
    cursor: pointer;
    display: none;
    border-radius: 100%;
    overflow: hidden;
  }
  .hidden {
    display: none;
  }
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
  svg {
    opacity: 1;
  }
}
.floatActionsBox:hover {
  background-color: #fff;
  opacity: 0.7;
  transition: all ease-in-out 0.1s;
  display: flex;
  gap: 5px;
  overflow: hidden;
  width: 'fit-content';
  height: 30px;
  align-items: center;
  .no-action {
    display: none;
  }
  .action-icon {
    transition: all 0.1s ease-in;
    display: inline-flex;
    cursor: pointer;
  }
  .action-icon {
    font-size: larger;
  }
  .hidden {
    display: none;
  }
}
//grid search field
.gridSearchField.MuiAutocomplete-root {
  height: 27px;
  min-height: 27px;
  .MuiFormControl-root {
    height: 27px;
    padding: 4px 0 !important;
    .MuiAutocomplete-clearIndicator {
    color : #f05252;
    }
    .MuiOutlinedInput-root {
      padding: 0;
      min-height: 27px;
      .MuiAutocomplete-input {
        padding-top: 0;
        padding-bottom: 0;
      };
     
    }
  }
}

.kgm-cross-tab-grid {
  .ag-header {
    background-color: #fff;
  }
  .ag-header-row {
    color: #a3a3a3;
  }
  .ag-row {
    color: #333;
  }
}
.ag-row-hover:not(.ag-row-inline-editing):not(.ag-row-pinned):not(.ag-full-width-row) {
  .floating-action-container {
    display: flex;
    visibility: visible;
    transition: visibility 5s;
  }
}

.floating-action-container {
  display: none;
  height: fit-content;
  width: fit-content;
  opacity: 0.8;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #ccc;
  min-width: 30px;
  padding: 0;
  margin: 0;
  min-height: 2px;
  position: relative;
  top: 0px;
  left: 0;
  .floating-action-icon {
    width: 24px;
    height: 24px;
    margin-left: 2px;
    svg {
      position: relative;
    }
  }

  .floating-action-icon {
    display: inline-flex;
    &:hover {
      transform: scale(1.2);
    }
  }
}

@keyframes scale-display {
  0% {
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes scale-display--reversed {
  0% {
    display: inline-flex;
    opacity: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  99% {
    display: inline-flex;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    display: none;
    opacity: 0;
    transform: scale(0);
    -webkit-transform: scale(0);
  }
}
.cell-number-currency {
  display: inline-flex;
  align-items: center;
  gap: 2px;

  svg {
    margin: -2px 0;
    font-size: 14px;
  }
}
.kgm-ellipsis-span-cell > div > span.ag-cell-value {
  width: 100% !important;
  display: block !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
  padding: 0;
  white-space: nowrap !important;
  text-align: left;
}
.ag-header-cell , .ag-cell{
  padding: 0px 6px!important;
}
.kgm-grid  .ag-header, .kgm-grid .ag-header-cell{
  min-height: 36px!important;
  height: 36px!important;
}

.kgm-number-cell-alignRight .ag-cell-value {
  flex-direction: row-reverse;
}
.kgm-number-cell-alignCenter .ag-cell-value {
  flex-direction: column;
}
.kgm-number-cell-alignLeft .ag-cell-value {
  flex-direction: initial;
}
.ag-row-footer {
  font-weight: bold;
  background-color: #f8f8f8;
}

.ag-left-aligned-cell-popup {
  text-align: left;
}

.dn {
  display: none !important;
}

.editIconKanban {
  display: none;
}
.radioInGrid {
  .MuiRadio-root {
    padding: 0 10px;
  }
  .MuiFormControlLabel-label {
    font-size: 14px;
  }
}

.custom-ag-menu-list {
  .custom-ag-menu-option {
    padding: 10px;
    font-weight: 400;
    &:hover {
      background-color: rgba(33, 150, 243, 0.1);
    }
  }
}

.ag-theme-alpine.ledgerAccountReport-custom .ag-header-cell-text {
  padding-right: 10px;
}
.ag-header-cell {
  .ag-header-cell-comp-wrapper > div > svg {
    position: absolute;
    margin-left: 8px;
    width: 16px !important;
  }
}
.ag-row-editing{
    border: 1px solid #006e7f!important;
    border-bottom: 1px solid #006e7f!important;
    border-top: 1px solid #006e7f!important;
  }
  .ag-header-cell .ag-header-cell-comp-wrapper > div > a , .ag-theme-alpine .ag-header-cell-text{
    font-weight: 500!important;
  }
  
  // .ag-row-focus.ag-row-editing .MuiAutocomplete-root{
  //   // min-width:200px!important;
  // }
  .ag-row-focus.ag-row-editing input  , .ag-row-focus.ag-row-editing fieldset ,.ag-row-focus.ag-row-editing .ag-cell{
    border:none!important;
    border-radius: 0px!important;
    // box-shadow: none!important;
    outline:inherit!important;
    border-color: #006e7f;
  }
  .ag-row-focus.ag-row-editing .ag-cell.ag-cell-focus {
    // box-shadow: inherit!important;
    // border: inherit!important;
    // border: 2px solid black!important;
    border: 2px solid #006e7f!important;
   
   }
  
  //  .ag-ltr .ag-body{
  //   padding-bottom: 4px!important;
  
  //  }
   .ag-row-focus.ag-row-editing .ag-cell.ag-cell-focus input ,   .ag-row-focus.ag-row-editing .ag-cell.ag-cell-focus .MuiInputBase-root{
    background-color: initial!important;
   }
   .ag-row-focus.ag-row-editing input , .ag-row-focus.ag-row-editing .MuiInputBase-root , .ag-row-focus.ag-row-editing .kgm-sno-cell{
    background-color: #d3eafd!important;
   }
  //  .ag-header , .ag-theme-alpine .ag-header-row > .ag-header-cell , .ag-header.ag-header-allow-overflow .ag-header-row{
  //   height:30px!important;
  //   max-height: 30px!important;
  //   min-height:30px!important;
  //  }
  
  .fieldPresentation .MuiOutlinedInput-root:hover fieldset{
    border-color: #006e7f!important;
  }
  .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul>div:hover{
    background: rgba(255, 255, 255, 0.2)!important;
   }
  .grid_filters_list >div:hover{
    border-color: #006e7f!important;
    background-color: #f5f8fa;
   }
  .kgm_pagination_list >div:first-child{
    height: calc(100% -  23px)!important;
  }
  
  .kgm_nonfield_button {
    border-color: #006e7f!important;
    background-color:#006e7f!important ;
    padding: 6px 8px;
    border-radius: 4px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
  }

  .ag-cell-value .kgm_nonfield_button{
    padding: 0px 5px !important;
    font-size: 10px;
    height: 24px;
    line-height: 24px;
  }
 