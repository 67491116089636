.auditlog-container {
  .ag-cell-value {
    width: 100%;
  }
  .auditlog-cell {
    padding-right: 0px !important;
    .ag-cell-wrapper {
      align-items: start;
      overflow-y: scroll;
    }
  }

  .actionbuttons {
    display: flex;
    min-height: 32px;
    padding: 2px 0px;
    button {
      background-color: #04263d;
      color: #fff;
      font-size: 16px;
      border: none;
      border-radius: 5px;
    }
  }

  .log_header {
    gap: 8px;
    color: #04263d;
    display: flex;
    font-size: 18px;
    align-items: center;
    font-weight: 600;
    margin-left: 2px;
    padding-top: 5px;
  }

  .auditlog-row {
    border-bottom: 1px solid #ccc;
  }
}
.audit-index1 {
  font-weight: bold;
  background-color: #ec7168 !important;
}

.audit-index2 {
  font-weight: bold;
  background-color: #8dce90 !important;
}
